import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import gsap from 'gsap'
import * as dat from 'lil-gui'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import * as Tone from 'tone'

// TONEJS
document.addEventListener('click', testClick, false)
const synth = new Tone.PolySynth().toDestination()
synth.volume.value = -16;
// const osc = new Tone.Oscillator().toDestination()

let clickStatus = false;
let toneTimer = 0;
let toneInterval = 4; // seconds


async function testClick() {

    Tone.start()
    clickStatus = !clickStatus; // if then boolean shortcut
    // console.log(clickStatus)

}


/**
 * Base
 */
// Debug
// This puts the controls menu on the screen
// const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// RANDOM KAROKE STRING PASSCODE
// Program to generate random strings
// Declare all characters
// const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-=+!@$%&*';

// function generateString(length) {
//     let results = ' ';
//     const charactersLength = characters.length;
//     for (let i = 0; i < length; i++) {
//         results += characters.charAt(Math.floor(Math.random() * charactersLength));
//     }

//     return results; // result
// }

// let karaoke = generateString(8)
// console.log(karaoke);

/**
 * Fonts
 */
const fontLoader = new FontLoader()

var groupGN = new THREE.Group();

fontLoader.load(
    '/fonts/Lato_Regular.json',
    (font) => {
        // Material
        //const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
        const material = new THREE.MeshNormalMaterial()

        // Text
        const textGeometry = new TextGeometry(
            'SI         IN',
            {
                font: font,
                size: 0.4, // .5
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            }
        )

        const textGeometry2 = new TextGeometry(
            'G',
            {
                font: font,
                size: 0.4, // .5
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            }
        )
        const textGeometry3 = new TextGeometry(
            'N',
            {
                font: font,
                size: 0.4, // .5
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            }
        )

        // const textGeometry4 = new TextGeometry(
        //     karaoke,
        //     {
        //         font: font,
        //         size: 0.25, // .5
        //         height: 0.1,
        //         curveSegments: 12,
        //         bevelEnabled: true,
        //         bevelThickness: 0.015,
        //         bevelSize: 0.01,
        //         bevelOffset: 0,
        //         bevelSegments: 5
        //     }
        // )

        textGeometry.center()
        textGeometry2.center()
        textGeometry3.center()
        // textGeometry4.center()
        const textSiin = new THREE.Mesh(textGeometry, material)

        const textG = new THREE.Mesh(textGeometry2, material)
        textG.name = "gText"
        //textG.position.x = -.35; 

        const textN = new THREE.Mesh(textGeometry3, material)
        textN.name = "nText"
        //textN.position.x = 0.05; 

        // const textKaraoke = new THREE.Mesh(textGeometry4, material)
        // textKaraoke.position.y = -1.5; 


        groupGN.add(textN)
        groupGN.add(textG)
        groupGN.position.set(-.15, 0, 0);
        textG.position.x = -.2;
        textN.position.x = .2;

        scene.add(groupGN)
        // scene.add(textN)
        // scene.add(textG)
        scene.add(textSiin)
        // scene.add(textKaraoke)




        tick()
    }
)

// Donuts
// const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 32, 64)
const donutGeometry = new THREE.CircleGeometry(0.3, 0.2, 32, 64)
const material2 = new THREE.MeshNormalMaterial()
for (let i = 0; i < 500; i++) {
    const donut = new THREE.Mesh(donutGeometry, material2)
    donut.position.x = (Math.random() - 0.25) * 10
    donut.position.y = (Math.random() - 0.25) * 10
    donut.position.z = (Math.random() - 0.5) * 10
    donut.rotation.x = Math.random() * Math.PI
    donut.rotation.y = Math.random() * Math.PI
    const scale = Math.random()
    donut.scale.set(scale, scale, scale)

    scene.add(donut)
}

/** 
 * Tube Geometry
 */

class CustomSinCurve extends THREE.Curve {
    constructor(scale) {
        super();
        this.scale = scale;
    }

    getPoint(t, optionalTarget = new THREE.Vector3()) {
        const tx = t * 3 - 1.5;
        // const ty = Math.sin( 2 * Math.PI * t );
        const ty = Math.sin(2 * Math.PI * t);
        const tz = 0;
        return optionalTarget.set(tx, ty, tz).multiplyScalar(this.scale);
    }
}

// Create a closed wavey loop
let sc = 0.1752; // scale .25
const curve = new THREE.CatmullRomCurve3([
    // new THREE.Vector3(-8 * sc, -5 * sc, .1),
    // new THREE.Vector3(8 * sc, -5 * sc, .1),
    // new THREE.Vector3(8 * sc, 5 * sc, .1),
    // new THREE.Vector3(-8 * sc, 5 * sc, .1),

    new THREE.Vector3(-8 * sc, -5 * sc, .1),
    new THREE.Vector3(8 * sc, -5 * sc, .1),
    new THREE.Vector3(8 * sc, 5 * sc, .1),
    new THREE.Vector3(-8 * sc, 5 * sc, .1),


]);
curve.closed = true;
// curve.tension = 0.5; 
const points = curve.getPoints(50); // 50
const geometry = new THREE.BufferGeometry().setFromPoints(points);
// const material = new THREE.MeshNormalMaterial()
const material = new THREE.MeshNormalMaterial();

// Create the final object to add to the scene
const curveObject = new THREE.Line(geometry, material);
// scene.add( curveObject );

const extrudeSettings2 = {
    steps: 100,
    bevelEnabled: true,
    bevelThickness: 1, //1
    bevelSize: 1, //1
    bevelSegments: 3, //3
    extrudePath: curve
}

const length = .05, width = .05; //.1, .05
const shape = new THREE.Shape();
shape.moveTo(0, 0);
shape.lineTo(0, width);
shape.lineTo(length, width);
shape.lineTo(length, 0);
shape.lineTo(0, 0);

const geometry2 = new THREE.ExtrudeGeometry(shape, extrudeSettings2);
const mesh2 = new THREE.Mesh(geometry2, material);
scene.add(mesh2);

//

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(55, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 5
scene.add(camera)

// document.addEventListener( 'mousedown', init, false );
// function init() {
//     console.log("click")
// }

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */

// Clock
const clock = new THREE.Clock()
let rotCounter = 0;

// Tick
const tick = () => {
    const elapsedTime = clock.getElapsedTime()
    let locx = Math.sin(elapsedTime) * .75
    let locz = Math.cos(elapsedTime) * .25
    camera.position.x = locx
    camera.position.z = locz + 4
    camera.lookAt(0, 0, 0)
    //let rotSpeed = .01; 

    // CLICK ANYWHERE TO PLAY SOUND
    if (clickStatus == true) { // default is true;
        if (elapsedTime - toneTimer > toneInterval) {
            // synth.triggerAttackRelease("C4", "2n")
            const now = Tone.now()
            synth.triggerAttack("C3", now)
            synth.triggerAttack(["E3", "G3", "D3", "C3"], now + 0.5)
            synth.triggerRelease(["E3", "G3", "D3", "C3"], now + 1)
            synth.triggerAttack("A3", now + .75)
            synth.triggerAttack("B2", now + 1.25)
            synth.triggerAttack("C2", now + 1.5)
            synth.triggerAttack("E3", now + 1.75)
            synth.triggerAttack(["A2", "G2"], now + 2)
            synth.triggerRelease(["A2", "G2"], now + 2.5)
            synth.triggerAttack("C4", now + 2.25)
            synth.triggerRelease(["C3", ["E3", "G3", "D3", "C3"], "A3", "B2", "C2", "E3", ["A2", "G2"], "C4"], now + 3.5);

            toneTimer = elapsedTime;

        }
    }

    rotCounter += .008;
    // console.log( Math.sin(rotCounter) + 1); 
    // let rotSin = (Math.sin(rotCounter)+1) * (Math.PI * 2); 
    // let rotSpeed =  (Math.sin(rotCounter) +1) * 180 / 100;  //  Math.abs(Math.sin(rotCounter)/100);

    let rotSpeed = (Math.sin(rotCounter) + 1) / 100;
    let sinNum = Math.sin(rotCounter) + 1;
    //console.log((sinNum*180)/2) 
    let test = THREE.Math.degToRad((sinNum * 180) / 2)

    //oadval/(numCores*100)

    // console.log( Math.sin(rotCounter)); 
    scene.getObjectByName("gText").rotation.y = test; // += rotSpeed;  // += rotSpeed; 
    scene.getObjectByName("nText").rotation.y = test; // +=  rotSpeed;  
    groupGN.rotation.y = test; // += rotSpeed; 

    renderer.render(scene, camera)
    window.requestAnimationFrame(tick)
}

